//
// Section
// --------------------------------------------------
.section {
	padding-top: 30px;
	padding-bottom: 30px;
}

.section-header {
	margin-bottom: 35px;
	margin-top: 15px;
	padding-top: 15px;
	position: relative;

	&:after {
		background: $brand-info;
		background: $brand-info-to-primary-gradient;
		display: block;
		content: ' ';
		height: 2px;
		left: 0;
		position: absolute;
		right: 0;
		bottom: 0;
	}


	.section-title {
		font-size: 22px;
		font-weight: 600;
		margin-bottom: 0;
		margin-top: 0;
		padding-top: 0;
		padding-bottom: 15px;
		position: relative;
		text-transform: uppercase;
		text-align: left;

		&.section-subtitle {
			&:before {
				height: 1px;
			}
		}
	}

}

.section-inner-shadow {
	@include box-shadow(inset 0px 7px 10px 0px rgba(0,0,0,0.3), inset 0px -7px 10px 0px rgba(0,0,0,0.2));
}

.section-shadow-inner-top {
	@include box-shadow(inset 0px 7px 10px 0px rgba(0,0,0,0.3));
}

.section-shadow-inner-bottom {
	@include box-shadow(inset 0px -7px 10px 0px rgba(0,0,0,0.2));
}

.section-intro {
	background-position: -250px 20px;
	padding: 180px 0 100px 0;
	height: 450px;
	background-size: cover;
	@media (min-width: $grid-float-breakpoint) {
		background-position: center;
		height: 750px;
	}
	
	// position: relative;
	// height: 710px;
	// background-position: center 50px;
	// background-size: cover;
	// z-index: $content-zindex;
	// @include font-smoothing;

	.intro-container {
		position: absolute;
		@include center;
		background: rgba(255, 255, 255, 0.8);
		padding: 40px 30px 20px;
		width: 300px;

		@include MQ(M) {
			width: 550px;
		}

		h1 {		
			@include font-size(26px);
			color: $brand-primary;
			font-weight: 700;
			text-align: center;
			margin-bottom: 10px;
		}

		p {
			line-height: normal;
		}
	}
}

.section-heading {
	float: left;
	width: 100%;
	padding-bottom: 0;
	margin-bottom: 50px;
	clear: both;

	h2 {
		@media screen and (max-width: $screen-sm) {
			font-size: 30px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 300;
		color: $brand-lighter;
		@media screen and (max-width: $screen-sm) {
			font-size: 24px!important;
			line-height: 34px;
		}
	}
}

.section-title {
	text-align: center;
	padding-top: 30px;
	padding-bottom: 50px;
	display: table;
	width: 100%;

	h1 {
		color: $brand-primary;
	}

	h2 {
		font-size: $font-size-section-title;
		display: inline-block;
		color: $brand-primary;

		&:after {
			content: ' ';
			height: 4px;
			background: $brand-primary;
			display: block;
			margin-top: 15px;
		}
	}
}

