#breadcrumb {
	background: transparent;
	border-bottom:1px solid $brand-primary;
	border-radius:0;
	padding-left: 0;
	padding-right: 0;

	ul,li {
		font-family: $font-primary;
	}
}