.publications-list {
	padding: 0;
	margin: 0;

	li {
		@include list-unstyled();
		a {
			display: inline-block;
			@include clearfix();
			&:hover {
				text-decoration: none;
			}
		}
		
		//display: block;
		//@include clearfix();
		border-bottom: 1px solid rgba(45,56,69,.12);
		//padding-bottom: 30px;
		//margin-bottom: 40px;
		//position: relative;
		padding: 0px 0 30px 90px;

		.btn {
			margin-top: 10px;
		}

		p {
			padding: 0;
			margin: 0;
		}
		h3 {
			font-size:18px;
			margin-bottom: 5px;
			color: $brand-primary;
		}
	}

}

.btn-file {
	// height: auto;
	// padding: 28px 0;
	// font-size: 1.6rem;
	// line-height: 1.625em;
	// -ms-flex-align: start;
	// align-items: flex-start;
	// -ms-flex-pack: start;
	// justify-content: flex-start;
	// text-align: left;
	// white-space: normal;
	// padding-right: 20%;
}
.icon-file-pdf {
	width: 40px;
	height: 45px;
	position: relative;
	margin-left: -65px;
	top: 18px;

	&:before {
		content: "";
		width: 40px;
		height: 45px;
		background-image: url(../../img/file-pdf.png);
		background-size: 40px 45px;
		position: absolute;
		left: 0;
		top: 0;
	}
}