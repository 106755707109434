//
// Scaffolding
// --------------------------------------------------

html,
body{
	font-family: $font-primary;
	background: $brand-body-bg;
	font-weight: 300;
	position: relative;
	color: $brand-lighter;
	-webkit-animation: fadein 900ms;
   animation: fadein 900ms;
}

html, body { height: 100%; }

main { min-height: 100%; height: auto !important; height: 100%; margin: 0 auto -66px; }
#push { height:66px; }

body {
	&.overflow-hidden {
		/* when primary navigation is visible, the content in the background won't scroll */
		overflow: hidden;
	}
}

// Horizontal rules

hr {
	border-top-width: 2px;
	margin-bottom: 40px;
	margin-top: 40px;
}

a {
	color: $brand-secondary;
	@include transition(.5s);
	&:hover{
		text-decoration: underline;
		color: $brand-secondary-hover;
	}
	&:focus, &:active {
		outline: none;
	}
}

.btn {
	&:hover {
		text-decoration: none;
	}
}

.sm-gutter {
	div[class*="col-"] {
		padding-left: 5px;
		padding-right: 5px;
	}
}


@mixin img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}

@mixin flex {

   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: row; /* works with row or column */
   flex-direction: row;
   -webkit-align-items: center;
   align-items: center;
   -webkit-justify-content: center;
   justify-content: center;

}

.flex-container {
	@include flex;
}

.flex-item {
	height: 50%;
}