.index-page {

	.section-intro {
		background-color: #aff2fc;
		background-image: url('../../img/bg/home_bg.jpg');
		background-size: cover;
		background-position: -330px 20px;

		@media (min-width: $grid-float-breakpoint) {
			background-position: center center;
		}

		.container {
			text-align: center;

			img {
				height: 712px;
				display: inline-block;
			}
		}
	}



	h3 {
		font-size: 16px;
		font-weight: 700;
		color: $brand-primary;
		text-transform: uppercase;
		margin-bottom: 15px;
		text-align: center;
	}

	p {
		text-align: center;
	}

	#mainTab {
		@media (min-width: $grid-float-breakpoint) {
			margin-top: -87px;
			position: relative;
			z-index: 100;
		}

		.tab-content {
			@media (min-width: $grid-float-breakpoint) {
				height: 180px;
			}

			.section {
				padding-top: 20px;
			}

			h3 {
				margin-bottom: 5px;
				margin-top: 5px;
				font-size: 14px;
			}

			p {
				font-size: 14px !important;
			}

			.row {
				[class^="col-"] {
					margin-left: -5px;
					margin-right: -5px;
				}
			}
		}

		.nav-tabs {
			border-bottom: 0;
		}

		.nav {
			li {

				margin: 0;

				a {
					margin: 0;
					font-weight: 700;
					padding: 10px 40px;
					background: $brand-info;
					border-color: inherit;
					//@include translate(0,5px);

					&:hover {
						//@include translate(0,0);
						background-color: $brand-primary !important;
					}
				}

				// &:nth-child(1) {
				// 	a {
				// 		background: $brand-info;
				// 		border-color: inherit;
				// 	}
				// }

				&:nth-child(2) {
					a {
						background: #3c72ad;
						border-color: inherit;
					}
				}

				&:nth-child(3) {
					a {
						background: #407cbd;
						border-color: inherit;
					}
				}

				&:nth-child(4) {
					a {
						background: $brand-info;
						border-color: inherit;
					}
				}

				&.active {
					a {
						color: #fff;
						background:#243d58;//background: $brand-primary;
						border-color: transparent;
						@include translate(0,0);

						&:hover {
							@include translate(0,0);
						}
					}
				}
			}
		}
	}
}