//
// Panel
// --------------------------------------------------
@media (min-width: $grid-float-breakpoint) {
	.affix {
		top: 0;
		width: 262.5px;
		position: fixed;
	}
}

@media (max-width: $grid-float-breakpoint) {
    .affix {
        position: static;
    }
}



.panel-heading[data-toggle=collapse]:after {
	font: normal normal normal 14px/1 FontAwesome;
	content: "\f078";
	color: #0a5591;
	margin-top: -7px;
	position: absolute;
	right: 10px;
	top: 50%;
}

.panel-heading[data-toggle=collapse].collapsed:after {
	content: "\f054";
}
.panel-default>.panel-heading {
	color: #343434;
	background-color: #f5f5f5;
	border-color: #ddd;
	text-transform: uppercase;
}
.panel-heading[data-toggle=collapse] {
	cursor: pointer;
	padding-right: 30px;
	position: relative;
}
.panel-body {
	padding: 30px;
}
.panel-heading[data-toggle=collapse]:after {
	font: normal normal normal 14px/1 FontAwesome;
	content: "\f078";
	color: #0a5591;
	margin-top: -7px;
	position: absolute;
	right: 10px;
	top: 50%;
}