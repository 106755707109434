#work{
	.grid{
		height: 385px;
		background-size: cover;
		margin-bottom: 30px;
		position: relative;

		@media screen and (max-width: $screen-xs) {
			height: 400px;
		}

		.image-popup{
			position: absolute;
			top: 20px;
			bottom: 20px;
			left: 20px;
			right: 20px;
			background: rgba(255,255,255,.9);
			opacity: 0;
			
			.work-title{
				position: absolute;
				top: 50%;
				bottom: 20px;
				left: 20px;
				right: 20px;
				margin-top: -25px;
				span {
					color: lighten($brand-black, 50%);
					letter-spacing: 2px;
					font-size: 12px;
					text-transform: uppercase;
				}
				h3{
					margin-bottom: 10px;
				}
			}
		}

		&:hover{
			.image-popup{
				opacity: 1;
			}
		}
	}
}