.btn {
	@include transition(.3s);
}

.btn {
	text-transform: uppercase;
	letter-spacing: normal;
	@include transition(.3s);
	&.btn-primary {
		background: $brand-secondary;
		color: $brand-white;
		border: none!important;
		border: 0;
		border-radius: 0;
		&:hover, &:active, &:focus {
			box-shadow: none;
			background: $brand-secondary-hover;
		}
	}
	&:hover, &:active, &:focus {
		background: $brand-secondary-hover;
		color: $brand-white;
		outline: none!important;
	}
	&.btn-default {
		&:hover, &:focus, &:active {
			border-color: transparent;
		}
	}
}

.sibling-nav li {
	float: left;
	padding: 0 4px;

	a {
		display: block;
		background: $brand-secondary;
		color: #fff;
		padding: 8px 6px;
		line-height: 12px;
		&:hover, &:focus {
			text-decoration: none;
			background: $brand-secondary-hover;
		}

		i {
			font-size: 20px;
		}

	}

	span.end {
		display: block;
		background: $gray-light;
		color: #fff;
		padding: 8px 6px;
		line-height: 12px;

		i {
			font-size: 20px;
		}
	}

}