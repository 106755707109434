.contact_form {
	.error {
		font-size: 14px;
	}
	.button {
		&.success {
			color: $brand-success;
		}
	}
}
.form {
	.form-control {
		color: #a2a2a2;
		font-size: 16px;
	}
}
.form-control {
	display: block;
	width: 100%;
	height: 40px;
	padding: 10px 15px;
	font-size: 18px;
	line-height: 1.42857;
	color: #555555;
	background-color: #fff;
	background-image: none;
	border: 1px solid $gray-light;
	border-radius: 4px;
	box-shadow: none;

	&:focus, &:active {
		box-shadow: none;
		border: 1px solid $gray;
	}

	// box-sizing:content-box;
	// -webkit-box-sizing:content-box;
}

.form-buttons {
	text-align: left;
}

#submit {
	height: 57px;
	font-size: 20px;
}

.field {
	@media screen and (max-width: $screen-sm) {
		margin-bottom: 30px;
	}
}
.form-group {
	@media screen and (max-width: $screen-sm) {
		margin-bottom: 0px;	
	}
}