.about-tdf-page {
	#mainTab {
		margin-top: 0;

		.nav-tabs li {
			float: none;
		}
	}
}

.endowments li {
	float: left;
	background: #ffffff;
	text-align: center;
	list-style: none;
	padding: 20px 0px;
	vertical-align: middle;
	margin: 10px;
	width: 100%;
	@media (min-width: $grid-float-breakpoint) {
		width: 30.33333%;
	}
}

.endowments {
	width: 90%;
	padding: 0;
	margin: 20px auto 30px auto;
	li:nth-child(4) {
		@media (min-width: $grid-float-breakpoint) {
			margin-left: 16%;
		}
	}
	i.fa {
		display: block;
		font-size: 64px;
		padding: 15px 0;
		color: #366496;
	}
	img.img-icon {
		width: 100px;
		display: block;
		margin: auto auto 10px;
	}
}

.tdf-programs-page {
	img.img-icon {
		max-width: 120px;
		margin: 10px;
	}
}

.about-tdf-page {
	.thumbnail {
		.caption {
			.h4 {
				text-align: left !important;
			}
		}
	}
}

// .mission-vision {

// 	&.thumbnail {
// 		background: $brand-success;
// 		box-shadow: none !important;


// 		.caption {
// 			text-align: center; color: #fff;
// 			.h4 {
// 				font-size: 26px;
// 			}

// 			.caption-content p {
// 				font-size: 20px !important; color: #fff;
// 			}
// 		}
// 	}

// }



