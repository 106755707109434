//
// Owl Carousel
// --------------------------------------------------
.owl-carousel {
	.animated  {
		-webkit-animation-duration : 2000ms;
		animation-duration : 2000ms;

		-webkit-animation-delay : 700ms;
		animation-delay : 700ms;
	}

	.section-title {
		-webkit-animation-delay : 900ms;
		animation-delay : 900ms;
	}

	img[class*="figure-img"] {
		-webkit-animation-delay : 1400ms;
		animation-delay : 1400ms;

	}

	.animate-out {
		-webkit-animation-delay : 0ms;
		animation-delay : 0ms;
	}

	.figure-img-1 {
		//animation:intro-fadeIn 1s 1s ease both;
	}
}

.owl-dot {
	background: #fff;
	border: 2px solid #fff;
	border-radius: 12px;
	color: #fff;
	display: inline-block;
	height: 12px;
	margin: 5px 8px;
	width: 12px;
	
	&.active {
		background: transparent;
	}
}

.owl-dots {
	text-align: center;
	position: relative;
	top: 0;
}

#section-eraec5l1fk79bl {

	.owl-nav {
		display: none;
		
		@media (min-width: $grid-float-breakpoint) {
			display: block;
		}
	}
	.owl-next, .owl-prev {
		top: 50%;
		color: #fff;
		font-size: 40px;

	}
	.owl-next {
		right: 20px;
		position: absolute;
		//background: rgba(0, 0, 0, 0.5);
		padding: 10px;
		text-align: center;
		width: auto;		
	}

	.owl-prev {
		left: 20px;
		position: absolute;
		//background: rgba(0, 0, 0, 0.5);
		padding: 10px;
		text-align: center;
		width: auto;
	}
}


#section_utLoZGDGTA {
	.thumbnail {
		background: none;
		border:0;

		.caption {
			h2 { text-align: center; }
		}
	}
	.owl-item {
		text-align: center;

		img {
			@media (min-width: $screen-lg-min) {
				box-shadow: 0 19px 20px -12px rgba(0, 0, 0, 0.26);
			}
			display: inline-block;			
			width: auto;
			@media (min-width: $grid-float-breakpoint) {
				margin-bottom: 5px;
			}
		}

		.btn {
			box-shadow: none;
		}
	}

	.owl-stage-outer {
		padding: 20px 0 30px;
	}

	.owl-icons {
		font-size: 0;
		display: none;
		margin-top: 50px;
		text-align: center;
	}

	.owl-icon {
		color: $brand-primary;
		cursor: pointer;
		display: inline-block;
		font-size: 40px;
		margin: 0 25px;
		transition: color 0.3s;

		&.active,
		&:hover {
			color: $brand-success;
		}
	}

	.owl-nav {
		// width: 360px;
		margin: 0 auto;
	}

	.owl-prev,
	.owl-next {

		&.disabled {
			color: $gray;
			cursor: default;

			:hover {
				color: $gray;
			}
		}

		color: $brand-primary;
		margin-top: -2px;

		> .fa {
			font-size: 50px;
			line-height: 27px;
		}

		:hover {
			color: $brand-primary;
		}
	}

	.owl-prev {
		float: left;
	}

	.owl-next {
		float: right;
	}

	.owl-dots {
		display: table;
		margin: 0 auto;
	}

	.owl-dot {
		background-color: transparent;
		border-color: $brand-primary;
		color: #c3c6c9;
		font-size: 0;
		margin-left: 3px;
		margin-right: 3px;

		&.active {
			background-color: $brand-primary;
		}
	}

	@media (min-width: $screen-xs-min) {
		.owl-dot {
			margin-left: 8px;
			margin-right: 8px;
		}
	}

	@media (min-width: $screen-md-min) {
		//padding-bottom: 0;

		.owl-stage-outer {
			padding-bottom: 40px;
		}

		.owl-icons {
			display: block;
		}

		.owl-dots {
			//display: none;
		}

		.owl-prev{
			position: absolute;
			top: 164px;
			left: -59px;

			> .fa {
				font-size: 40px;
			}
		}

		.owl-next{
			position: absolute;
			top: 164px;
			right: -59px;

			> .fa {
				font-size: 40px;
			}
		}

		.owl-item {
			text-align: left;
		}
	}

	@media (min-width: $screen-lg-min) {
		.owl-icon {
			font-size: 60px;
		}
	}
}
