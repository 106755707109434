#product{
	.post-entry{
		.post{
			float: left;
			position: relative;
			margin-bottom: 80px;

			> a{
				max-width: 200px;
				position: absolute;
				top: 0;
				left: 0;
				img{
					max-width: 200px;
					max-height: 191px;
					@include border-radius(5px);

					@media screen and (max-width: $screen-xs) {
						max-width: 100%;
						width: 100%;
						max-height: 100%;
						height: 100%;
						margin-bottom: 30px;
					}
					
				}

				@media screen and (max-width: $screen-xs) {
					position: relative;
				}
			}

			> div{
				margin-left: 230px;

				h3{
					font-size: 24px;
					margin-bottom: 15px;
					a{
						color: $brand-black;
					}
				}
				p{
					margin-bottom: 10px;
				}
				span{
					font-size: 20px;
				}

				@media screen and (max-width: $screen-xs) {
					margin-left: 0;
				}
			}
		}
	}
}