//
// Utilities
// --------------------------------------------------

// Table
.tb-f {
	table-layout: fixed !important;
}

// Display
.d-t{
	display: table  !important;
}

.d-tc{
	display: table-cell  !important;
}

.d-tr{
	display: table-row  !important;
}

.d-b{
	display: block !important;
}

.d-ib{
	display: inline-block !important;
}

.d-n {
	display: none !important;
}

@media (min-width: $screen-xs-min) {
	.d-t-xs{
		display: table !important;
	}
	.d-tr-xs{
		display: table-row !important;
	}
	.d-tc-xs{
		display: table-cell !important;
	}

	// fix for row in ie
	.d-t-xs.row{
		margin: 0;
		table-layout: fixed;
		width: 100%;

		> div{
			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	.d-t-sm{
		display: table !important;
	}
	.d-tr-sm{
		display: table-row !important;
	}
	.d-tc-sm{
		display: table-cell !important;
	}

	// fix for row in ie
	.d-t-sm.row{
		margin: 0;
		table-layout: fixed;
		width: 100%;

		> div{
			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
			}
		}
	}
}

@media (max-width: $screen-md-min) {
	.d-n-md-max {
		display: none !important;
	}
}

@media (min-width: $screen-md-min) {
	.d-t-md{
		display: table !important;
	}
	.d-tc-md{
		display: table-cell !important;
	}
	.d-tr-md{
		display: table-row !important;
	}

	// fix for row in ie
	.d-t-md.row{
		margin: 0;
		table-layout: fixed;
		width: 100%;

		> div{
			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
			}
		}
	}
	.d-n-md {
		display: none !important;
	}

}

@media (min-width: $screen-lg-min) {
	.d-t-lg{
		display: table !important;
	}
	.d-tr-lg{
		display: table-row !important;
	}
	.d-tc-lg{
		display: table-cell !important;
	}

	// fix for row in ie
	.d-t-lg.row{
		margin: 0;
		table-layout: fixed;
		width: 100%;

		> div{
			&:first-child{
				padding-left: 0;
			}
			&:last-child{
				padding-right: 0;
			}
		}
	}
}

// Positioning
.p-a{
	position: absolute;
}

.p-f{
	position: fixed;
}

.p-r{
	position: relative;
}

.p-s{
	position: static;
}

// Vertical Aligning
.va-m{
	vertical-align: middle  !important;
}

.va-t{
	vertical-align: top  !important;
}

.va-b{
	vertical-align: bottom  !important;
}

// Visibility

.vv{
	visibility: visible !important;
}

.vh{
	visibility: hidden !important;
}

// Size

.fluid{
	@include square(100%  !important);
}

.fluid-h-width{
	width: 50%  !important;
}

.fluid-width{
	width: 100%  !important;
}

.fluid-h-height{
	height: 50%  !important;
}

.fluid-height{
	height: 100%  !important;
}

// Floating

.f-n{
	float: none !important;
}

@media (min-width: $screen-xs-min) {
	.f-n-xs{
		float: none !important;
	}
}

@media (min-width: $screen-sm-min) {
	.f-n-sm{
		float: none !important;
	}
}

@media (min-width: $screen-md-min) {
	.f-n-md{
		float: none !important;
	}
}

@media (min-width: $screen-lg-min) {
	.f-n-lg{
		float: none !important;
	}
}

.f-l{
	float: left !important;
}

@media (min-width: $screen-xs-min) {
	.f-l-xs{
		float: left !important;
	}
}

@media (min-width: $screen-sm-min) {
	.f-l-sm{
		float: left !important;
	}
}

@media (min-width: $screen-md-min) {
	.f-l-md{
		float: left !important;
	}
}

@media (min-width: $screen-lg-min) {
	.f-l-lg{
		float: left !important;
	}
}

.f-r{
	float: right !important;
}

@media (min-width: $screen-xs-min) {
	.f-r-xs{
		float: right !important;
	}
}

@media (min-width: $screen-sm-min) {
	.f-r-sm{
		float: right !important;
	}
}

@media (min-width: $screen-md-min) {
	.f-r-md{
		float: right !important;
	}
}

@media (min-width: $screen-lg-min) {
	.f-r-lg{
		float: right !important;
	}
}

// Overflow
.o-h {
	overflow: hidden;
}

.o-v {
	overflow: visibility;
}

// Text Align
.ta-l {
	text-align: left !important;
}

.ta-r {
	text-align: right !important;
}

.ta-c {
	text-align: center !important;
}

.ta-j {
	text-align: justify !important;
}

// Font Weight 
.fw-100 {
	font-weight: 100 !important;
}

.fw-200 {
	font-weight: 200 !important;
}

.fw-300 {
	font-weight: 300 !important;
}

.fw-400 {
	font-weight: 400 !important;
}

.fw-500 {
	font-weight: 500 !important;
}

.fw-600 {
	font-weight: 600 !important;
}

.fw-700 {
	font-weight: 700 !important;
}

.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

// // Padding;
// @include padding($util-padding);

// // Margin
// @include margin($util-margin);

@include padding();

@include margin();

.clearfix {
	&:after, &:before {
		visibility: hidden;
		display: block;
		font-size: 0;
		content: " ";
		clear: both;
		height: 0;
	}
}

.gotop {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 30px;
    background-color: $brand-primary;
    color: #fff;
    padding: 10px 16px;
    border-radius: 40px;
    text-decoration: none;
    display: none;

    &:hover, &:focus {
    	text-decoration: none;
    	color: rgba(255,255,255,.7);
    }
}

.gutter-sm {
	[class*="col-"] {
		padding: 5px;
	}
}

.thumbnail {
	border-radius: 0;

	.h5 {
		font-weight: 400;
		font-size: 14px;
		// color: $brand-lighter;
	}
}