#mainTab {
	margin-top: 0;

	.nav {
		padding: 10px;
		// background: $brand-primary;
	}

	.nav-tabs {
		border-bottom: 0;
	}

	.nav-tabs li {
		float: none;
	}
}
