// Social
.social {
	padding: 0;
	margin: 0;
	display: inline-block;
	position: relative;
	width: 100%;
	text-align: right;

	li {
		list-style: none;
		padding: 0;
		margin: 0;
		display: inline-block;
		a {
			font-size: 24px;
			display: table;
			width: 24px;
			height: 24px;
			margin: 0 4px;
			color: $brand-black;
			@include transition(.5s);
			&:hover, &:focus{
				color: $brand-primary;
				text-decoration: none;
			}

			i {
				display: table-cell;
				vertical-align: middle;
			}
		}
	}

	@media screen and (max-width: $screen-md) {
		text-align: center;
	}
}