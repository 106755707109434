/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */

.timeline-container { /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */


	&:after { /* clearfix */
		content: '';
		display: table;
		clear: both;
	}
}

/* -------------------------------- 

Main components 

-------------------------------- */

#timeline {
	position: relative;
	padding: 2em 0;
	margin: {
		top: 2em;
		bottom: 2em;
	}

	&:before {
		/* this is the vertical line */
		content: '';
		position: absolute;
		top: 0;
		left: 18px;
		height: 100%;
		width: 4px;
		background: darken($background, 5%);
	}

	@include MQ(L) {
		margin: {
			top: 3em;
			bottom: 3em;
		}

		&:before {
			left: 50%;
			margin-left: -2px;
		}
	}
}

.timeline-block {
	position: relative;
	margin: 2em 0;
	@include clearfix;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include MQ(L) {
		margin: 4em 0;

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.timeline-img {
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 0 0 0 4px $color-3, inset 0 2px 0 rgba(#000, .08), 0 3px 0 4px rgba(#000, .05) ;

	img {
		display: block;
		width: 24px;
		height: 24px;
		position: relative;
		left: 50%;
		top: 50%;
		margin-left: -12px;
		margin-top: -12px;
	}

	&.picture {
		background: $brand-info;
	}

	&.movie {
		background: $color-2;
	}

	&.location {
		background: $color-5;
	}

	@include MQ(L) {
		width: 60px;
		height: 60px;
		left: 50%;
		margin-left: -30px;

		/* Force Hardware Acceleration in WebKit */
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;

		&.is-hidden {
			visibility: hidden;
		}

		&.bounce-in {
			visibility: visible;
			@include animation(bounce-1 .6s);
		}
	}
}

@include keyframes(bounce-1) {
	0% {
		opacity: 0;
		@include transform(scale(.5));
	}

	60% {
		opacity: 1;
		@include transform(scale(1.2));
	}

	100% {
		@include transform(scale(1));
	}
}

.timeline-content {
	position: relative;
	margin-left: 60px;
	background: $background;
	@include border-radius;
	padding: 1em;
	box-shadow: 0 3px 0 darken($background, 5%);
	@include clearfix;

	h2 {
		color: $color-1;
		font-weight: 700;
		margin-bottom: 10px;
	}

	p, .read-more, .date {
		@include font-size(13px);
	}

	.read-more, .date {
		display: inline-block;
	}

	p {
		//margin: 1em 0;
		//line-height: 1.6;
	}

	.read-more {
		float: right;
		padding: .8em 1em;
		background: $link;
		color: $color-3;
		@include border-radius;

		.no-touch &:hover {
			background-color: lighten($link, 5%);
		}
	}

	.date {
		float: left;
		padding: .8em 0;
		opacity: .7;
	}

	&:before {
		content: '';
		position: absolute;
		top: 16px;
		right: 100%;
		height: 0;
		width: 0;
		border: 7px solid transparent;
		border-right: 7px solid $background;
	}

	@include MQ(M) {
		h2 {
			@include font-size(20px);
		}

		p {
			@include font-size(16px);
		}

		.read-more, .date {
			@include font-size(14px);
		}
	}

	@include MQ(L) {
		margin-left: 0;
		padding: 1.6em;
		width: 45%;

		&:before {
			top: 24px;
			left: 100%;
			border-color: transparent;
			border-left-color: $background;
		}

		.read-more {
			float: left;
		}

		.date {
			position: absolute;
			width: 100%;
			left: 122%;
			top: 6px;
			@include font-size(16px);
		}

		.timeline-block:nth-child(even) & {
			float: right;

			&:before {
				top: 24px;
				left: auto;
				right: 100%;
				border-color: transparent;
				border-right-color: $color-3;
			}

			.read-more {
				float: right;
			}

			.date {
				left: auto;
				right: 122%;
				text-align: right;
			}
		}

		&.is-hidden {
			visibility: hidden;
		}

		&.bounce-in {
			visibility: visible;
			@include animation(bounce-2 .6s);
		}
	}
}

@include MQ(L) {
	/* inverse bounce effect on even content blocks */
	.timeline-block:nth-child(even) .timeline-content.bounce-in {
		@include animation(bounce-2-inverse .6s);
	}
}

@include keyframes(bounce-2) {
	0% {
		opacity: 0;
		@include transform(translateX(-100px));
	}

	60% {
		opacity: 1;
		@include transform(translateX(20px));
	}

	100% {
		@include transform(translateX(0));
	}
}

@include keyframes(bounce-2-inverse) {
	0% {
		opacity: 0;
		@include transform(translateX(100px));
	}

	60% {
		opacity: 1;
		@include transform(translateX(-20px));
	}

	100% {
		@include transform(translateX(0));
	}
}
