#services {
	.service {
		text-align: center;
		padding: 0 2em;
		margin-bottom: 40px;
		float: left;
		width: 100%;
		@media screen and (max-width: $screen-xs) {
			padding: 0 0em;
		}
		h2 {
			font-size: 22px;
			margin-bottom: 10px;
		}
		.service-icon {
			margin-bottom: 30px;
			i {
				font-size: 40px;
				color: $brand-primary;
			}
		}
	}
}