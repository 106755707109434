// colors

$color-1: #021725; // blue
$color-2: #e36767; // red
$color-3: #fff; // white

// fonts 

//$primary-font: 'Source Sans Pro', sans-serif;

//header height

$header-height-S: 80px;
$header-height-M: 80px;

// z-index 

$content-zindex: 1;
$nav-zindex: 2;
$header-zindex: 3;
