.sisea-result {
	.extract {
		margin-bottom: 30px;
	}
	h3 {
		margin-bottom: 10px;
		font-size: 20px;
	}
}

.sisea-highlight {
	color: $brand-success;
}

.sisea-paging {
	span.sisea-page.sisea-current-page {
		padding: 5px 10px;
		background: rgba(54, 100, 150, 0.22);
		margin: 0;
		color: #333;

		
	}

	span.sisea-page a {
		padding: 5px 10px;
		background: #366496;
		margin: 5px;
		color: #fff;
		&:hover, &:focus {
			text-decoration: none;
		}
	}

	span.sisea-page a {
		color: #fff;
	}

	.sisea-result-pages {
		margin-right: 10px;
	}
}