//
// Variables
// --------------------------------------------------

//== Colors
//
//## 

$gray-base:             		#1a1a1a !default;
$gray-darker:           		lighten($gray-base, 13.5%) !default;  
$gray-dark:             		lighten($gray-base, 20%)   !default;    
$gray:                  		lighten($gray-base, 40%) !default;  
$gray-light:            		lighten($gray-base, 62.0%) !default;  
$gray-lighter:          		lighten($gray-base, 87.2%) !default; 

$brand-primary: 						#366496 !default;
$brand-secondary:						#366496 !default; 
$brand-info: 		    				#5093dc !default;
$brand-success: 		    		#30ab67 !default;

$brand-secondary-hover: 		lighten($brand-secondary, 11%)   !default;
$brand-primary-hover: 			lighten($brand-primary, 11%)   !default;

$brand-primary-font: 				#fff !default;

$top-bar-bg:		 						$brand-primary;
$top-bar-font:		 						#fff;

$bg-color-footer:						#000;
$font-color-footer:					#fff;

$bg-gray:										#f3f1ec;
$color-tab-line:						#ff0000;

$green-light: 							#eaf7ee;
$green-light-opacity-50: 		rgba($green-light,0.5);

$brand-pink:				     				#ee3071;
$brand-success-opacity-50: 		 		rgba($brand-success,0.8);
$brand-info-opacity-50: 		 			rgba($brand-info,0.8);
$brand-pink-opacity-50: 		 			rgba($brand-pink, 0.8);
$brand-primary-opacity-50: 		 		rgba($brand-primary, 0.8);

$brand-info-to-primary-gradient:	linear-gradient(to right, $brand-info-opacity-50 0, $brand-primary-opacity-50 100%) !default;
$brand-info-to-pink-gradient:     linear-gradient(to right, $brand-info-opacity-50 0, $brand-pink-opacity-50 100%) !default;
$brand-pink-to-info-gradient:     inear-gradient(to right, $brand-pink-opacity-50 0, $brand-info-opacity-50 100%) !default;
$brand-green-light-gradient:     linear-gradient(to left, $green-light 0, $green-light-opacity-50 100%) !default;

//== Typography
//
//##
$font-primary: 'Roboto Condensed', sans-serif;
$font-secondary: 'Source Sans Pro', sans-serif;//'Roboto Condensed', sans-serif;
//$font-family-electolize: 'Agency FB', 'Electrolize', sans-serif;
//$font-family-abel: 'Agency FB', 'Abel', sans-serif;
//$font-family-sans-serif:  Verdana, Arial, sans-serif;//'Helvetica Neue', Helvetica, Arial, sans-serif !default;

$headings-font-weight:    600 !default; 

$hr-border:               $gray !default;

$font-size-section-title:    24px !default;

//== Buttons
//
//##

$brand-white: #fff;
$brand-black: #000;
$brand-lighter: #333333;
$brand-selection-color: $brand-secondary;
$brand-body-color: #fcfcfc;
$brand-body-bg: #ffffff;


$input-border-focus: $brand-primary !default;
$form-group-margin-bottom: 30px !default;

//== Forms
//
//##

$input-border-radius:            0 !default;

$input-border-radius-large:      0 !default;

$input-border-radius-small:      0 !default;

$input-border-focus: 			 $brand-primary !default;

//== Media queries breakpoints
//
//##

$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;

//== Grid system
//
//##

$grid-float-breakpoint:     	$screen-md-min !default;

//== Panels
//
//##

$panel-bg:                    #fff !default;
$panel-body-padding:          30px !default;
$panel-heading-padding:       10px !default;
$panel-sub-heading-padding:   10px 30px !default;
$panel-border-radius:         0;


//== Progress bars
//
//##

$progress-bg:                 #d1e2ea !default;

$progress-bar-color:          #fff !default;

$progress-border-radius:      0 !default;

//== Utilities
//
//##

// Margin
$margins:	0,5,10,15,20,30,40,50;

// Padding
$paddings:	0,5,10,15,20,30,40,50;

$main-text: #7f8c97; // main text
$link: #acb7c0; // anchor tags
$background: #e9f0f5; // body background color

$color-1: #303e49; // blue dark
$color-2: #c03b44; // red
$color-3: $background; // white
$color-4: #75ce66; // green
$color-5: #f0ca45; // yellow

