@font-face {
    font-family: 'simple-line-icons';
    src:    url('../fonts/simple-line-icons/simple-line-icons.eot?k0bwyk');
    src:    url('../fonts/simple-line-icons/simple-line-icons.eot?k0bwyk#iefix') format('embedded-opentype'),
        url('../fonts/simple-line-icons/simple-line-icons.ttf?k0bwyk') format('truetype'),
        url('../fonts/simple-line-icons/simple-line-icons.woff?k0bwyk') format('woff'),
        url('../fonts/simple-line-icons/simple-line-icons.svg?k0bwyk#simple-line-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-line-"], [class*=" icon-line-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'simple-line-icons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-line-user-female:before {
    content: "\e000";
}
.icon-line-people:before {
    content: "\e001";
}
.icon-line-user-follow:before {
    content: "\e002";
}
.icon-line-user-following:before {
    content: "\e003";
}
.icon-line-user-unfollow:before {
    content: "\e004";
}
.icon-line-user:before {
    content: "\e005";
}
.icon-line-trophy:before {
    content: "\e006";
}
.icon-line-speedometer:before {
    content: "\e007";
}
.icon-line-social-youtube:before {
    content: "\e008";
}
.icon-line-social-twitter:before {
    content: "\e009";
}
.icon-line-social-tumblr:before {
    content: "\e00a";
}
.icon-line-social-facebook:before {
    content: "\e00b";
}
.icon-line-social-dropbox:before {
    content: "\e00c";
}
.icon-line-social-dribbble:before {
    content: "\e00d";
}
.icon-line-shield:before {
    content: "\e00e";
}
.icon-line-screen-tablet:before {
    content: "\e00f";
}
.icon-line-screen-smartphone:before {
    content: "\e010";
}
.icon-line-screen-desktop:before {
    content: "\e011";
}
.icon-line-plane:before {
    content: "\e012";
}
.icon-line-notebook:before {
    content: "\e013";
}
.icon-line-mustache:before {
    content: "\e014";
}
.icon-line-mouse:before {
    content: "\e015";
}
.icon-line-magnet:before {
    content: "\e016";
}
.icon-line-magic-wand:before {
    content: "\e017";
}
.icon-line-hourglass:before {
    content: "\e018";
}
.icon-line-graduation:before {
    content: "\e019";
}
.icon-line-ghost:before {
    content: "\e01a";
}
.icon-line-game-controller:before {
    content: "\e01b";
}
.icon-line-fire:before {
    content: "\e01c";
}
.icon-line-eyeglass:before {
    content: "\e01d";
}
.icon-line-envelope-open:before {
    content: "\e01e";
}
.icon-line-envolope-letter:before {
    content: "\e01f";
}
.icon-line-energy:before {
    content: "\e020";
}
.icon-line-emotsmile:before {
    content: "\e021";
}
.icon-line-disc:before {
    content: "\e022";
}
.icon-line-cursor-move:before {
    content: "\e023";
}
.icon-line-crop:before {
    content: "\e024";
}
.icon-line-credit-card:before {
    content: "\e025";
}
.icon-line-chemistry:before {
    content: "\e026";
}
.icon-line-bell:before {
    content: "\e027";
}
.icon-line-badge:before {
    content: "\e028";
}
.icon-line-anchor:before {
    content: "\e029";
}
.icon-line-wallet:before {
    content: "\e02a";
}
.icon-line-vector:before {
    content: "\e02b";
}
.icon-line-speech:before {
    content: "\e02c";
}
.icon-line-puzzle:before {
    content: "\e02d";
}
.icon-line-printer:before {
    content: "\e02e";
}
.icon-line-present:before {
    content: "\e02f";
}
.icon-line-playlist:before {
    content: "\e030";
}
.icon-line-pin:before {
    content: "\e031";
}
.icon-line-picture:before {
    content: "\e032";
}
.icon-line-map:before {
    content: "\e033";
}
.icon-line-layers:before {
    content: "\e034";
}
.icon-line-handbag:before {
    content: "\e035";
}
.icon-line-globe-alt:before {
    content: "\e036";
}
.icon-line-globe:before {
    content: "\e037";
}
.icon-line-frame:before {
    content: "\e038";
}
.icon-line-folder-alt:before {
    content: "\e039";
}
.icon-line-film:before {
    content: "\e03a";
}
.icon-line-feed:before {
    content: "\e03b";
}
.icon-line-earphones-alt:before {
    content: "\e03c";
}
.icon-line-earphones:before {
    content: "\e03d";
}
.icon-line-drop:before {
    content: "\e03e";
}
.icon-line-drawar:before {
    content: "\e03f";
}
.icon-line-docs:before {
    content: "\e040";
}
.icon-line-directions:before {
    content: "\e041";
}
.icon-line-direction:before {
    content: "\e042";
}
.icon-line-diamond:before {
    content: "\e043";
}
.icon-line-cup:before {
    content: "\e044";
}
.icon-line-compass:before {
    content: "\e045";
}
.icon-line-call-out:before {
    content: "\e046";
}
.icon-line-call-in:before {
    content: "\e047";
}
.icon-line-call-end:before {
    content: "\e048";
}
.icon-line-calculator:before {
    content: "\e049";
}
.icon-line-bubbles:before {
    content: "\e04a";
}
.icon-line-briefcase:before {
    content: "\e04b";
}
.icon-line-book-open:before {
    content: "\e04c";
}
.icon-line-basket-loaded:before {
    content: "\e04d";
}
.icon-line-basket:before {
    content: "\e04e";
}
.icon-line-bag:before {
    content: "\e04f";
}
.icon-line-action-undo:before {
    content: "\e050";
}
.icon-line-action-redo:before {
    content: "\e051";
}
.icon-line-wrench:before {
    content: "\e052";
}
.icon-line-umbrella:before {
    content: "\e053";
}
.icon-line-trash:before {
    content: "\e054";
}
.icon-line-tag:before {
    content: "\e055";
}
.icon-line-support:before {
    content: "\e056";
}
.icon-line-size-fullscreen:before {
    content: "\e057";
}
.icon-line-size-actual:before {
    content: "\e058";
}
.icon-line-shuffle:before {
    content: "\e059";
}
.icon-line-share-alt:before {
    content: "\e05a";
}
.icon-line-share:before {
    content: "\e05b";
}
.icon-line-rocket:before {
    content: "\e05c";
}
.icon-line-question:before {
    content: "\e05d";
}
.icon-line-pie-chart:before {
    content: "\e05e";
}
.icon-line-pencil:before {
    content: "\e05f";
}
.icon-line-note:before {
    content: "\e060";
}
.icon-line-music-tone-alt:before {
    content: "\e061";
}
.icon-line-music-tone:before {
    content: "\e062";
}
.icon-line-microphone:before {
    content: "\e063";
}
.icon-line-loop:before {
    content: "\e064";
}
.icon-line-logout:before {
    content: "\e065";
}
.icon-line-login:before {
    content: "\e066";
}
.icon-line-list:before {
    content: "\e067";
}
.icon-line-like:before {
    content: "\e068";
}
.icon-line-home:before {
    content: "\e069";
}
.icon-line-grid:before {
    content: "\e06a";
}
.icon-line-graph:before {
    content: "\e06b";
}
.icon-line-equalizer:before {
    content: "\e06c";
}
.icon-line-dislike:before {
    content: "\e06d";
}
.icon-line-cursor:before {
    content: "\e06e";
}
.icon-line-control-start:before {
    content: "\e06f";
}
.icon-line-control-rewind:before {
    content: "\e070";
}
.icon-line-control-play:before {
    content: "\e071";
}
.icon-line-control-pause:before {
    content: "\e072";
}
.icon-line-control-forward:before {
    content: "\e073";
}
.icon-line-control-end:before {
    content: "\e074";
}
.icon-line-calender:before {
    content: "\e075";
}
.icon-line-bulb:before {
    content: "\e076";
}
.icon-line-chart:before {
    content: "\e077";
}
.icon-line-arrow-up-circle:before {
    content: "\e078";
}
.icon-line-arrow-right-circle:before {
    content: "\e079";
}
.icon-line-arrow-left-circle:before {
    content: "\e07a";
}
.icon-line-arrow-down-circle:before {
    content: "\e07b";
}
.icon-line-ban:before {
    content: "\e07c";
}
.icon-line-bubble:before {
    content: "\e07d";
}
.icon-line-camrecorder:before {
    content: "\e07e";
}
.icon-line-camera:before {
    content: "\e07f";
}
.icon-line-check:before {
    content: "\e080";
}
.icon-line-clock:before {
    content: "\e081";
}
.icon-line-close:before {
    content: "\e082";
}
.icon-line-cloud-download:before {
    content: "\e083";
}
.icon-line-cloud-upload:before {
    content: "\e084";
}
.icon-line-doc:before {
    content: "\e085";
}
.icon-line-envolope:before {
    content: "\e086";
}
.icon-line-eye:before {
    content: "\e087";
}
.icon-line-flag:before {
    content: "\e088";
}
.icon-line-folder:before {
    content: "\e089";
}
.icon-line-heart:before {
    content: "\e08a";
}
.icon-line-info:before {
    content: "\e08b";
}
.icon-line-key:before {
    content: "\e08c";
}
.icon-line-link:before {
    content: "\e08d";
}
.icon-line-lock:before {
    content: "\e08e";
}
.icon-line-lock-open:before {
    content: "\e08f";
}
.icon-line-magnifier:before {
    content: "\e090";
}
.icon-line-magnifier-add:before {
    content: "\e091";
}
.icon-line-magnifier-remove:before {
    content: "\e092";
}
.icon-line-paper-clip:before {
    content: "\e093";
}
.icon-line-paper-plane:before {
    content: "\e094";
}
.icon-line-plus:before {
    content: "\e095";
}
.icon-line-location-pin:before {
    content: "\e096";
}
.icon-line-power:before {
    content: "\e097";
}
.icon-line-refresh:before {
    content: "\e098";
}
.icon-line-reload:before {
    content: "\e099";
}
.icon-line-settings:before {
    content: "\e09a";
}
.icon-line-star:before {
    content: "\e09b";
}
.icon-line-symble-female:before {
    content: "\e09c";
}
.icon-line-symbol-male:before {
    content: "\e09d";
}
.icon-line-target:before {
    content: "\e09e";
}
.icon-line-volume-1:before {
    content: "\e09f";
}
.icon-line-volume-2:before {
    content: "\e0a0";
}
.icon-line-volume-off:before {
    content: "\e0a1";
}
.icon-line-phone:before {
    content: "\e600";
}
.icon-line-Menu:before {
    content: "\e601";
}
.icon-line-optionsvertical:before {
    content: "\e602";
}
.icon-line-options:before {
    content: "\e603";
}
.icon-line-arrow-down:before {
    content: "\e604";
}
.icon-line-arrow-left:before {
    content: "\e605";
}
.icon-line-arrow-right:before {
    content: "\e606";
}
.icon-line-arrow-up:before {
    content: "\e607";
}
.icon-line-paypal:before {
    content: "\e608";
}
.icon-line-social-instagram:before {
    content: "\e609";
}
.icon-line-social-linkedin:before {
    content: "\e60a";
}
.icon-line-social-pintarest:before {
    content: "\e60b";
}
.icon-line-social-github:before {
    content: "\e60c";
}
.icon-line-social-gplus:before {
    content: "\e60d";
}
.icon-line-social-reddit:before {
    content: "\e60e";
}
.icon-line-social-skype:before {
    content: "\e60f";
}
.icon-line-social-behance:before {
    content: "\e610";
}
.icon-line-social-foursqare:before {
    content: "\e611";
}
.icon-line-social-soundcloud:before {
    content: "\e612";
}
.icon-line-social-spotify:before {
    content: "\e613";
}
.icon-line-social-stumbleupon:before {
    content: "\e614";
}

