//
// Layout
// --------------------------------------------------
$S:     320px;   
$M:     768px;     
$L:     1170px;     

// media queries

@mixin MQ($canvas) {
  @if $canvas == S {
   @media only screen and (min-width: $S) { @content; } 
  }
  @else if $canvas == M {
   @media only screen and (min-width: $M) { @content; } 
  }
  @else if $canvas == L {
   @media only screen and (min-width: $L) { @content; } 
  }
}

.box-wrap {
	background: $brand-white;
	max-width: 1300px;
	margin: 0 auto;
}

.container-940 {
	width: 940px !important;
}
.container-md {
	width: 1000px !important;
}
.col-left, .col-right {
	position: relative;
	min-height: 1px;
}

.col-left {
	width: 370px;
	float: left;
	white-space: normal;
	word-wrap: break-word;
	word-break: break-word;
	padding-right: 50px;
	font-size: 12px;
}
.col-right {
	width: 530px;
	float: left;
	padding-left: 50px;
}

body.sticky-layout{

	.scrollable{
		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
		position: relative;		
	}

	.page{
		display: table;
		table-layout: fixed;
		@include square(100%);
	}

	.horizontal-divider {
		@include gradient-vertical(#e9f2f7, #fff, 0, 100%);
		height: 20px;
		margin-bottom: 10px;
	}

	header[role="banner"],
	main[role="main"],
	footer[role="contentinfo"]{
		display: table-row;
		height: 1px;
		width: 100%;

		> .inner{
			display: table-cell;
			width: 100%;
		}	
	}

	main[role="main"]{
		height: 100%;

		> .inner{
			.section:first-child{
				// padding-top: 40px !important;

				@media (min-width: $grid-float-breakpoint){
    			// padding-top: 	130px !important;
    		};
    	}


    }
  }
}