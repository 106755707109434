//
// Typography
// --------------------------------------------------

// Body text
// -------------------------

p,ul,li {
	margin-bottom: 10px;
	//font-size: 15px;
	color: $brand-lighter;
	font-weight: 300;
	font-family: $font-secondary;
}

h1, h2, h3, h4, h5, h6 {
	color: $brand-black;
	font-family: $font-primary;
	font-weight: 400;
	margin: 0 0 30px 0;
}
::-webkit-selection {
	color: $brand-body-color;
	background: $brand-selection-color;
}

::-moz-selection {
	color: $brand-body-color;
	background: $brand-selection-color;
}

::selection {
	color: $brand-body-color;
	background: $brand-selection-color;
}


// Emphasis & misc
// -------------------------

@include text-emphasis-variant('.text-white', #fff);

@include text-emphasis-variant('.text-primary', $brand-primary);

@include text-emphasis-variant('.text-success', $brand-success);

@include text-emphasis-variant('.text-info', $brand-info);

@include text-emphasis-variant('.text-date', $gray-light);

@include text-emphasis-variant('.text-warning', $brand-warning);

@include text-emphasis-variant('.text-danger', $brand-danger);

// Contextual backgrounds
@include bg-variant('.bg-white', #fff);

@include bg-variant('.bg-primary', $brand-primary);

@include bg-variant('.bg-gray-darker', $gray-darker);

@include bg-variant('.bg-gray-dark', $gray-dark);

@include bg-variant('.bg-gray', $gray);

@include bg-variant('.bg-gray-light', $gray-light);

@include bg-variant('.bg-gray-lighter', $gray-lighter);

@include bg-variant('.bg-success', $brand-success);

@include bg-variant('.bg-info', $brand-info);

@include bg-variant('.bg-warning', $brand-warning);

@include bg-variant('.bg-danger', $brand-danger);


// Blockquotes
blockquote{
	background-color: #fff;
	border-left: 0;
	font-size: 14px;
	font-style: italic;
	margin-bottom: 30px;
	padding: 35px 15px;
	box-shadow: -5px 0px 0px 0px #eee;
}

// Addresses
address {
  	margin-bottom: 10px;
}

.error {
	color: #ff0000;
	font-weight: 400;
}
