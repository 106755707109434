//
// Footer
// --------------------------------------------------

footer[role="footer"] {
	border-bottom: 4px solid $brand-primary;
	padding-top: 20px;
	background: #efefef;
	padding-bottom: 10px;
	
	p {
		line-height: 1;
	}

	ul.footer-links {
		float: left;
		list-style: none;
		padding: 0;
	}

	ul.footer-links li {
		float: left;
		margin: 0 10px;

		&:first-child {
			margin: 0;
		}
	}

	ul.social-links {
		float: right;
		list-style: none;
		padding: 0;
	}

	ul.social-links li {
		float: left;

		a {
			margin: 0 2px;
			padding: 8px 10px;

			&:hover {
				background-color: $brand-primary;
				color: #fff;
			};
		}

		&:nth-child(2) {
			a:hover {
				background-color: $brand-info;
				color: #fff;
			}
		}

		&:nth-child(3) {
			a:hover {
				background-color: $brand-danger;
				color: #fff;
			}
		}
	}

	.border {
		border-top: 1px solid lighten($brand-black, 90%);
		padding: 2em 0;
		float: left;
		width: 100%;
		@media screen and (max-width: $screen-sm) {
			padding: 2em 15px!important;
		}
		@media screen and (max-width: $screen-xs) {
			padding: 2em 15px!important;
			padding-bottom: 2em!important;
		}
	}
	p {
		margin-bottom: 0;	
	}
	small {
		text-transform: uppercase;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: .01em;
		font-family: $font-secondary;
	}
	.social {
		text-align: center;
	}
}