//
// Mixins
// --------------------------------------------------

// Mixin
@mixin transition($transition) {
    -moz-transition:    all $transition ease;
    -o-transition:      all $transition ease;
    -webkit-transition: all $transition ease;
    -ms-transition:         all $transition ease;
    transition:         all $transition ease;
}
@mixin inline-block() {
    display:-moz-inline-stack;
    display:inline-block;
    zoom:1;
    *display:inline;
}
@mixin flex() {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}
@mixin flex() {
    display: -webkit-box;      
    display: -moz-box;         
    display: -ms-flexbox;      
    display: -webkit-flex;     
    display: flex;             
}
@mixin flexwrap() {
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap; 
    -moz-flex-wrap: wrap; 
}

@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
      -ms-transform: translate($x, $y); // IE9 only
       -o-transform: translate($x, $y);
          transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
  -webkit-transform: translate3d($x, $y, $z);
          transform: translate3d($x, $y, $z);
}

@font-face {
    font-family: 'icomoon';
    src:url('../fonts/icomoon/icomoon.eot?srf3rx');
    src:url('../fonts/icomoon/icomoon.eot?srf3rx#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?srf3rx') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?srf3rx') format('woff'),
    url('../fonts/icomoon/icomoon.svg?srf3rx#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@mixin icomoon() {
    
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
}

// Margin
@mixin margin(){

    @each $margin in $margins {

        .m-#{$margin} {
            margin: #{$margin}px !important; 
        }

        .ml-#{$margin} {
            margin-left: #{$margin}px !important; 
        }

        .mr-#{$margin} {
            margin-right: #{$margin}px !important; 
        }

        .mt-#{$margin} {
            margin-top: #{$margin}px !important; 
        }

        .mb-#{$margin} {
            margin-bottom: #{$margin}px !important; 
        }
    }

}

// Padding
@mixin padding(){

    @each $padding in $paddings {

        .p-#{$padding} {
            padding: #{$padding}px !important; 
        }

        .pl-#{$padding} {
            padding-left: #{$padding}px !important; 
        }

        .pr-#{$padding} {
            padding-right: #{$padding}px !important; 
        }

        .pt-#{$padding} {
            padding-top: #{$padding}px !important; 
        }

        .pb-#{$padding} {
            padding-bottom: #{$padding}px !important; 
        }
    }
    
}

// Transformations
@mixin transform($transform) {
  -webkit-transform: $transform;
      -ms-transform: $transform; // IE9+
          transform: $transform;
}

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content; 
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  } 
}

/*
    Example usage: 
    @include animation(10s, 5s, changecolour)
 */
@mixin animation ($animation, $delay, $duration) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    -o-animation-delay: $delay;
    -o-animation-duration: $duration;
    -o-animation-name: $animation;
    -o-animation-fill-mode: forwards; /* this prevents the animation from restarting! */

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: forwards; /* this prevents the animation from restarting! */
}

// Clearfix

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}