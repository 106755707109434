//
// Pages
// --------------------------------------------------

@import 'pages/*';

#intro, #work, #product, #about, #services {
	padding-bottom: 5em;
	@media screen and (max-width: $screen-sm) {
		padding-bottom: 3em;
	}
}
#intro {
	padding-bottom: 0;
}
#main {
	padding-bottom: 7em;
	@media screen and (max-width: $screen-sm) {
		padding-bottom: 3em;
	}
}
.intro{
	h2{
		font-family: $font-secondary;
		font-size: 30px;
		font-weight: 300;
		line-height: 1.5em;
	}
}

.animate-box {
	.js & {
		opacity: 0;
	}
}

.inner-page {
	.thumbnail {
		border: 0;
	}
	#mainTab {
		.thumbnail {
			&.bg-primary {
				background: -webkit-linear-gradient(left, #f4f8fb 0, #e8eff9 100%);
				border: 0;
				.caption {
					color: $brand-primary;
				}

			}
		}
	}

	.section-intro {
		background-position: -250px 20px;
		padding: 180px 0 100px 0;
		height: 375px;
		background-size: cover;

		@media (min-width: $grid-float-breakpoint) {
			height: 500px;
			background-position: 0 -20px;
		}
	}

	h1 {
		background-color: transparent;
		margin-bottom:15px;
	}

}

.board-of-trustees-page, .institutional-review-board-page, .careers-page, .about-tdf-page, .mission-and-vision-page {
	.h3 {
		color: $brand-primary;
		padding-bottom: 20px;
		font-size: 24px !important;
	}
	.h4 a {
		//color: #fff;
	}
	.thumbnail {
		background: $brand-green-light-gradient ;//rgba(48, 171, 92, 0.10);
		box-shadow: none !important;

		.caption {
			padding: 10px 30px;
			//color: #fff;
			.h4 {
				text-align: center;
			}

			p,ul,li {
				//font-size: 20px !important; 
				//color: #fff;
			}
		}
	}

	.grid-item { 
		width: 410px; 
		//margin-bottom: 10px;
	}
}

#sidenav {
	.btn {
		background: -webkit-linear-gradient(left, #f4f8fb 0, #e8eff9 100%);
		color: $brand-primary;
		text-align: left;
		font-size: 16px;
		font-weight: 700;
		padding-top: 10px;
		padding-bottom: 10px;

		&:hover, &:focus {
			background: $gray-lighter;
		}

		&.active {
			background: $brand-primary;
			box-shadow: none;
			color: #fff;
		}
	}

}

.privacy-statement-page, .inner-page {
	.h3 {
		font-size: 20px;
	}
}

.inner-about {
	div[class*="col-md"] {
		> div {
			text-align: center !important;
			background: #ffc28f;
			padding: 20px;
			min-height: 250px;
			margin-bottom: 30px;

			img {
				display: inline-block;
				width: 150px;
			}
		}
		&:nth-child(2) > div {
			background: #e1effa;
		}
		&:nth-child(3) > div {
			background: #fee3b2;
		}
		&:nth-child(4) > div {
			background: #cbd4dc;
		}
	}
}