//
// Navbar
// --------------------------------------------------
#tabs li {
	font-family: $font-primary;
}
.navbar-collapse {
	background: $brand-primary;
	.nav a {
		color: #fff;
		cursor: pointer;
	}	

	@media (min-width: $grid-float-breakpoint) {
		background: inherit;
	}

	.nav {
		a {
			&:hover, &:focus {
				background-color: #5093dc;
			}
		}
	}
	
}

.nav-search {
	margin-top: 5px;
	@media (max-width: $screen-xs-max) {
		margin-bottom: -30px;
	}
	button {
		background-color: transparent;
		border: 0;
		color: #fff;
		font-size: 23px;
		outline: 0;
		padding: 10px 15px;
		position: relative;
		z-index: 1;
	}

	&.open {
		i {
			color: $brand-primary;
		}
		.nav-search-control {
			width: 100%;
		}	
	}

	@media (max-width: $screen-xs-max) {
		button {
			height: 45px;
			padding: 0;
			width: 45px;
			 top: -40px;
		}

		position: static !important;
	}

	@media (min-width: $screen-sm-min) {
		// position: relative;

		button {
			font-size: 16px;
			line-height: 16px;
		}

		&.open {
			.nav-search-control {
				width: 361px;
			}	
		}
	}
}

.nav-search-control {
	bottom: 0;
	// left: 0;
	overflow: hidden;
	position: relative;
	right: 1px;
	top: 0;
	width: 0;
	transition: width 0.6s;
	@media (min-width: $grid-float-breakpoint) {
		position: absolute;
	}


	input {
		border: 0;
		font-size: 14px;
		height: 100%;
		padding: 10px 15px 10px 50px;
		outline: 0;
		width: 100%;
		@media (min-width: $grid-float-breakpoint) {
			padding: 10px 60px 10px 15px;
		}
	}
}


.cd-container { /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
	width: 90%;
	max-width: $M; // breakpoints inside partials > _layout.scss
	margin: 0 auto;
	@include clearfix;
}

.cd-header {
	position: absolute;
	top: 0;
	left: 0;
	background: $brand-primary;
	height: $header-height-S;
	width: 100%;
	z-index: $header-zindex;
	box-shadow: 0 1px 2px rgba(#000, .3);
	@include font-smoothing;

	@include MQ(M) {
		height: $header-height-M;
		background: $brand-primary;
		box-shadow: none;
	}

	@include MQ(L) {
		@include transition(background-color .3s);

		/* Force Hardware Acceleration in WebKit */
		@include transform(translate3d(0,0,0));
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;

		&.is-fixed {
			/* when the user scrolls down, we hide the header right above the viewport */
			position: fixed;
			top: -$header-height-M;
			background-color: $brand-primary;
			-webkit-transition: -webkit-transform 0.3s;
			-moz-transition: -moz-transform 0.3s;
			transition: transform 0.3s;
		}

		&.is-visible {
			/* if the user changes the scrolling direction, we show the header */
			@include transform(translate3d(0,100%,0));
		}

		&.menu-is-open {
			/* add a background color to the header when the navigation is open */
			background-color: $brand-primary;
		}
	}
}

.cd-logo {
	float: left;
	margin-top: 15px;
	margin-left: 15px;
	
	img {
		display: block;
		width: 250px;

		@include MQ(M) {
			width: auto;
		}
	}

}

.navbar-nav {
	.active a {
		border-bottom: 2px solid $brand-info;
	}
	@media (min-width: $grid-float-breakpoint) {
		float: right;
		margin-top: 15px;
		display: none;


		li {
			display: inline-block;
		}

		a {
			display: inline-block;
			color: $color-3;
			text-transform: uppercase;
			font-weight: 400;
			font-size: 13px;
		}
	}

	@include MQ(L) {
		display: block;
	}
}

.cd-primary-nav-trigger {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 50px;
	background-color: lighten($color-1, 5%);

	.cd-menu-text {
		color: $color-3;
		text-transform: uppercase;
		font-weight: 700;
		/* hide the text on small devices */
		display: none;
	}

	.cd-menu-icon {
		/* this span is the central line of the menu icon */
		display: inline-block;
		position: absolute;
		@include center; // mixin inside partials > _mixins.scss
		width: 18px;
		height: 2px;
		background-color: $color-3;
		@include transition(background-color .3s);
		
		/* these are the upper and lower lines in the menu icon */
		&::before, &:after {
			content: '';
			width: 100%;
			height: 100%; 
			position: absolute;
			background-color: $color-3;
			right: 0;

			-webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
			-moz-transition: -moz-transform .3s, top .3s, background-color 0s;
			transition: transform .3s, top .3s, background-color 0s;
		}
		
		&::before {
			top: -5px;
		}

		&::after {
			top: 5px;
		}

		&.is-clicked {
			background-color: rgba($color-3, 0);

			&::before, &::after {
				background-color: rgba($color-3, 1);
			}

			&::before {
				top: 0;
				@include transform(rotate(135deg));
			}

			&::after {
				top: 0;
				@include transform(rotate(225deg));
			}
		}
	}

	@include MQ(M) {
		width: 100px;
		padding-left: 1em;
		background-color: transparent;
		height: 30px;
		line-height: 30px;
		right: 2.2em;
		@include center(y);

		.cd-menu-text {
			display: inline-block;
		}

		.cd-menu-icon {
			left: auto;
			right: 1em;
			@include transform(translateX(0) translateY(-50%));
		}
	}
}

.cd-primary-nav {
	/* by default it's hidden - on top of the viewport */
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background: $brand-primary;
	z-index: $nav-zindex;
	text-align: center;
	padding: $header-height-S 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;

	overflow: auto;
	/* this fixes the buggy scrolling on webkit browsers - mobile devices only - when overflow property is applied */
	-webkit-overflow-scrolling: touch;

	@include transform(translateY(-100%));
	@include transition-property(transform);
	@include transition-duration(.4s);

	li {
		font-weight: 300;
		@include font-smoothing;
		margin: .2em 0;
		text-transform: capitalize;
	}

	a {
		display: inline-block;
		padding: .4em 1em;
		@include border-radius;
		@include transition(background .2s);

		.no-touch &:hover {
			background-color: $color-2;
		}
	}

	.cd-label {
		color: lighten($color-1, 15%);
		text-transform: uppercase;
		font-weight: 700;
		@include font-size(14px);
		margin: 2.4em 0 .8em;
	}

	.cd-social {
		display: inline-block;
		margin: 0 .4em;
		
		a {
			width: 44px;
			height: 44px;
			padding: 0;
			background-image: url('../img/cd-socials.svg');
			background-repeat: no-repeat;

			/* image replacement */
			overflow: hidden;
			text-indent: 100%;
			white-space: nowrap;
		}
	}

	.cd-facebook a {
		background-position: 0 0;
	}

	.cd-instagram a {
		background-position: -44px 0;
	}

	.cd-dribbble a {
		background-position: -88px 0;
	}

	.cd-twitter a {
		background-position: -132px 0;
	}

	&.is-visible {
		@include transform(translateY(0));
	}

	@include MQ(M) {
		padding: $header-height-M 0;
	}

	@include MQ(L) {
		li {
			@include font-size(16px);
		}

		.cd-label {
			@include font-size(16px);
		}
	}
}

#mainTab {

	.nav {
		
		li {
			font-family: $font-primary;
			a {
				background: $brand-primary;
				border-color: $brand-primary;
				border-radius: 0;
				color: #fff;
				border: 0;

				&:hover {
					background: $brand-primary-hover;
				}
			}

			&.active {
				a {
					color: $brand-primary;
					background: #fff;
					border-color: transparent;
					font-weight: 700;
				}
			}
		}
	}
}


